<template>
  <div class="app-container">
    <el-container class="main-container">
      <el-header>
        <h1>个人资料</h1>
      </el-header>
      <el-container>
        <el-aside width="130px">
          <!-- <ul>
            <li :class="baseSet" @click="showBaseSet">基本信息</li>
            <li :class="passwordSet" @click="showPasswordSet">密码设置</li>
          </ul> -->
          <el-menu
            default-active="base"
            class="el-menu-vertical-demo"
            @select="handleMenuSelect">
            <el-menu-item index="base">基本信息</el-menu-item>
            <el-menu-item index="pwd">密码设置</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <div v-show="menu == 'base'">
            <h2>基本信息</h2>
            <el-form ref="form" :rules="rules" :model="form" :class="basePage" status-icon label-width="120px">
              <el-form-item label="头像" >
                <el-upload
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :action="uploadUrl"
                  class="avatar-uploader">
                  <img v-if="form.userImageUrl" :src="form.userImageUrl | DFSImg" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-form-item>
              <el-form-item label="姓名" prop="realName">
                <el-input v-model="form.realName" placeholder="请输入姓名" maxlength="100" required/>
              </el-form-item>
              <el-form-item :disabled="true" label="所属组织" prop="orgName" >
                <span v-for="(item, index) in form.employeeOrgs" :key="index" class="orgNameSpan">{{ item.orgName }}</span>
              </el-form-item>
              <el-form-item label="联系电话" prop="phoneNumber">
                <el-input v-model="form.phoneNumber" placeholder="请输入联系电话" />
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="form.email" placeholder="例如：example@163.com" />
              </el-form-item>
              <el-form-item label="账号">
                <el-input v-model="form.userName" :disabled="true" placeholder="请输入账号" />
              </el-form-item>
              <el-form-item label="用户性别">
                <el-radio-group v-model="form.gender">
                  <el-radio v-for="item in getSysCode('gender')" :key="item.key" :label="item.key">{{ item.display_name }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="入职时间">
                <el-input v-model="form.entryTime" :disabled="true" />
              </el-form-item>
              <el-form-item label="身份证号" prop="identityCard">
                <el-input v-model="form.identityCard" placeholder="请输入身份证号" />
              </el-form-item>
              <el-form-item label="生日">
                <el-col :span="11">
                  <el-date-picker v-model="form.birth" type="date" placeholder="选择日期" style="width:320px;" />
                </el-col>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="margin-left: 20px;" @click="onSubmit">保存</el-button>
                <el-button style="margin-left: 40px;" @click="handleCancle">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="menu == 'pwd'">
            <h2>密码设置</h2>
            <el-form ref="passwordForm" :rules="passwordRules" :class="passwordPage" :model="passwordForm" status-icon label-width="120px">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="passwordForm.oldPassword" type="password" placeholder="请输入旧密码" />
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="passwordForm.newPassword" type="password" placeholder="请输入新密码" />
              </el-form-item>
              <el-form-item label="确认密码" prop="rePassword">
                <el-input v-model="passwordForm.rePassword" type="password" placeholder="请再次输入密码" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="margin-left: 30%;" @click="onUpdatePassword">修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {
  getInfo,
  saveInfo,
  updateLoginUserPassword
} from '@/api/system/loginInfo'

import {
  findEmployeeRoleOrg
} from '@/api/employee/employee'

export default {
  name: 'PersonalInfo',
  data() {
    var checkIdentityCard = (rule, value, callback) => {
      const identityCardReg = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
      setTimeout(() => {
        if (identityCardReg.test(value)) {
          callback()
        } else {
          callback(new Error('身份证号格式不正确'))
        }
      }, 100)
    }
    var checkPhoneNumber = (rule, value, callback) => {
      const phoneReg = /^((\(\d{2,3}\))|(\d{3}\-))?1\d{10}$/
      setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('电话号码格式不正确'))
        }
      }, 100)
    }
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-z0-9]*[-_.]?[a-z0-9]+)+@([a-z0-9]*[-_]?[a-z0-9]+)+[\.][a-z]{2,3}([\.][a-z]{2})?$/i
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }
    var checkRePassword = (rule, value, callback) => {
      const newPwd = this.passwordForm.newPassword
      setTimeout(() => {
        if (newPwd === value) {
          callback()
        } else {
          callback(new Error('两次密码不一致'))
        }
      }, 100)
    }

    return {
      menu: 'base',
      LoginInfoSave: 'LoginInfoSave',
      LoginInfoCancle: 'LoginInfoCancle',
      loginOrgInfo: {},
      baseSet: 'selectLi',
      passwordSet: '',
      // 基础设置框
      basePage: 'showDiv',
      // 密码设置框
      passwordPage: 'hideDiv',
      // 上传地址
      uploadUrl: process.env.BASE_API + '/baseService/upload/uploadFile.htm',

      imageUrl: '',
      oldForm: {},
      form: {},
      passwordForm: {},
      rules: { // 校验规则
        // 用户名
        realName: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        // 身份证号
        // identityCard: [{
        //   required: true,
        //   message: '请输入身份证号',
        //   trigger: 'blur'
        // }, {
        //   validator: checkIdentityCard,
        //   trigger: 'blur'
        // }],
        // 联系电话
        phoneNumber: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, {
          validator: checkPhoneNumber,
          trigger: 'blur'
        }],
        // 邮箱
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          validator: checkEmail,
          trigger: 'blur'
        }]
      },
      passwordRules: { // 密码校验规则
        // 旧密码
        oldPassword: [{
          required: true,
          trigger: 'blur',
          min: 6,
          max: 16,
          message: '密码长度6-16位'
        }],
        // 新密码
        newPassword: [{
          required: true,
          trigger: 'blur',
          min: 6,
          max: 16,
          message: '请输入新密码,长度6-16位'
        }],
        // 确认密码
        rePassword: [{
          required: true,
          message: '请再次输入新密码',
          trigger: 'blur'
        }, {
          validator: checkRePassword,
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    this.getLoginUserInfo()
  },
  methods: {
    getLoginUserInfo() {
      getInfo().then(response => {
        if (response.data.code === '200') {
          const data = response.data.data
          this.oldForm = data
          this.form = Object.assign({}, data)
          // this.form.id = data.id
          // this.form.realName = data.realName
          // this.form.identityCard = data.identityCard
          // this.form.phoneNumber = data.phoneNumber
          // this.form.userName = data.userName
          // this.form.userImageUrl = data.userImageUrl
          // this.form.email = data.email
          // this.form.gender = data.gender
          // this.form.birth = data.birth
          // findEmployeeRoleOrg(this.form.id).then(res => {
          //   if (res.data.code === '200') {
          //     const data = res.data.data
          //     this.loginOrgInfo = data[0].orgList
          //   }
          // })
          this.passwordStrength = ''
        }
      })
    },
    handleCancle() {
      this.form = Object.assign({}, this.oldForm)
    },
    showBaseSet() {
      this.baseSet = 'selectLi'
      this.passwordSet = ''
      this.basePage = 'showDiv'
      this.passwordPage = 'hideDiv'
    },
    showPasswordSet() {
      this.baseSet = ''
      this.passwordSet = 'selectLi'
      this.basePage = 'hideDiv'
      this.passwordPage = 'showDiv'
    },
    // 个人信息修改
    onSubmit() {
      this.form.updateDatetime = new Date()
      this.$refs['form'].validate(valid => {
        if (valid) {
          saveInfo(this.form).then(response => {
            if (response.data.code === '200') {
              this.oldForm = Object.assign({}, this.form)
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success',
                duration: 2000
              })
              this.$refs['form'].clearValidate()
            }
          })
        }
      })
    },
    onUpdatePassword() {
      this.$refs['passwordForm'].validate(valid => {
        if (valid) {
          // 封装后台对象
          var data = this.oldForm
          data.passWord = this.passwordForm.newPassword
          data.prePassword = this.passwordForm.oldPassword
          updateLoginUserPassword(data).then(response => {
            if (response.data.code === '200') {
              // this.oldForm = Object.assign({}, this.form)
              this.$notify({
                title: '成功',
                message: '修改成功，请重新登录',
                type: 'success',
                duration: 2000
              })
              // this.$refs['form'].clearValidate()
              this.$store.dispatch("FedLogOut").then(() => {
                location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
              });
            } else {
              this.$notify({
                title: '失败',
                message: response.msg,
                type: 'warm',
                duration: 2000
              })
            }
          })
        }
      })
    },
    // 头像上传相关方法
    handleAvatarSuccess(res, file) {
      this.form.userImageUrl = res.data.remoteFileUrl
    },
    beforeAvatarUpload(file) {
      // 检查图片大小格式
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      var flag = true
      if (!isJPG && !isPNG) {
        flag = false
        this.$message.error('上传头像图片只能是 JPG,PNG 格式!')
      }
      if (!isLt2M) {
        flag = false
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return flag
    },
    handleMenuSelect(index) {
      this.menu = index
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container{
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
  .main-container{
    background: #fff;
    border-radius: 10px;
    .el-header{
      border-bottom: 1px solid #e6e6e6;
    }
    h1{
      margin: 20px 0;
    }
    h2{
      margin-bottom: 20px;
    }
    .el-menu{
      height: 100%;
    }
  }
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
</style>

