import request from '@/utils/request'

export function getInfo() {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/employeeInfo/getLoginUserInfo`,
    method: 'get'
  })
}

export function saveInfo(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/employeeInfo/updateLoginUserInfo`,
    method: 'post',
    data
  })
}

// 更改登录用户密码
export function updateLoginUserPassword(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/employeeInfo/password`,
    method: 'post',
    data
  })
}
